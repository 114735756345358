<template>
  <edit-role-form
      :abilities="generalAbilities"
      :accessibility="accessibility"
      :associated-abilities="associatedAbilities"
      :is-associated-section="!isAdmin()"
      :is-loading="isLoading"
      :repository="repository"
      :role-name="name"
      is-update-form
      @refresh-data="$router.replace({name: 'roles'})"
  />
</template>

<script>
import EditRoleForm from '@/components/roles/form.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    EditRoleForm,
  },
  data() {
    return {
      name: "",
      generalAbilities: {},
      associatedAbilities: {},
      accessibility: [],
      isLoading: false,
      repository: RepositoryFactory.create('roleRepo'),
    }
  },
  async created() {
    this.isLoading = true
    await this.showRecord()
    this.isLoading = false
  },
  methods: {
    async showRecord() {
      await this.repository.get(this.$route.params.id)
          .then(res => {
            this.name = res.data.data.name
            this.generalAbilities = res.data.data.permissionsModularity.general ?? {}
            this.associatedAbilities = res.data.data.permissionsModularity.associated ?? {}
            this.accessibility = res.data.data.accessibility
          });
    },
  }
}
</script>
